.grey-bg {
  background-color: grey;
  height: 100%;
}
.card-home {
  width: 400px;
  height: 400px;
  background-color: white;
}
.logo-home {
  height: 70px;
  width: 220px;
  background-color: white;
}
.heading-home {
  font-size: 20px;
}
.subheading-home {
  font-size: 12px;
  color: grey;
}
