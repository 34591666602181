.tab {
  .active {
    color: #cdc4c7
    ;
    opacity: 8.0;
    color:white;
  }
  .inactive {
    color: #cdc4c7

    ;
    opacity: 0.5;
    color:black;
  }
  .underline {
    border-bottom-width: 1.5px;
    border-bottom-style: solid;
 padding-bottom:2px;
 border-bottom-color: #ff9400;
    background-color: #ff9400;
    width:30%;
  }
}

.justify-content-left{
  justify-content: left !important;
}
.text-left{
  text-align: left !important;
}
.horizontal-line {
  border-bottom: 2px solid red !important;

}
.nopadding {
  margin-left: 0;
  margin-right: 0;
  [class*="col"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
