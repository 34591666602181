
.card {
  border-radius: 10px ;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  transition-duration: 0.4s;
}
.card:hover{
.hover-required
{
  background-color: #47365a;
  color: white;
}
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-card{
  height:200px;margin:20px;
}
.detail-card{
  height:450;
  margin-top:20px;
  margin-left:100px;
  margin-right: 100px;
  // width: 950px;

}
@media (max-width: 767.98px) {

    .detail-card{

      margin-left:10px;
      margin-right: 10px;
      // width: 950px;

    }
}
@media (max-width: 767.98px) {



    .details-heading {
      font-size: 10px;
      font-weight: 500;
      color: #251438;
    }


}

