.inline-block {
  display: inline-block;
}
* {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

body {
  background: #f5f6fa;
}

 .sidebar {
  background: #251438;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  z-index:1001;
  // padding: 20px 0;
  transition: all 0.5s ease;
  &.collapseMenu{
    width:10px!important;
  }
}
 .sidebar .profile {
  margin-bottom: 30px;
  text-align: center;
}

 .sidebar .profile img {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

 .sidebar .profile h3 {
  color: #ffffff;
  margin: 10px 0 5px;
}

 .sidebar .profile p {
  color: rgb(206, 240, 253);
  font-size: 14px;
}
 .sidebar ul li a {
  display: block;
  padding: 13px 30px;
  border-bottom: 1px solid #251438;
  color: rgb(241, 237, 237);
  font-size: 16px;
  position: relative;
}

 .sidebar ul li p .icon {
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}
 .sidebar ul li p:hover,
 .sidebar ul li p.active {
  color: #251438;

  background: white;
  border-right: 2px solid #251438;
}
 .sidebar ul li p.active {
  color: #251438;

  background: white;
  border-right: 2px solid #251438;
}
 .sidebar ul li p.inactive {
  color: #fff;

  background: #251438;
  border-right: 2px solid #251438;
}
 .sidebar ul li p:hover .icon,
 .sidebar ul li p.active .icon {
  color: #251438;
}

 .sidebar ul li p:hover:before,
.sidebar ul li p.active:before {
  color: white;

  background: #251438;
  display: block;
}
 .section {
  width: calc(100% - 245px);
  margin-left: 250px;
  transition: all 0.5s ease;
}

 .top_navbar {
  background: #54435d;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  position:fixed;
  z-index: 1001px;
  top:0px;
  left:0px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
 .top_navbar .hamburger a {
  font-size: 28px;
  color: #f4fbff;
}

 .section .top_navbar .hamburger a:hover {
  color: #a2ecff;
}
body.active  .sidebar {
  left: -225px;
}

body.active  .section {
  margin-left: 0;
  width: 100%;
}
.link {
  color: #007bff;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
//-----
.text-primary {
  color: #251438 !important;
}
.spinner {
  border-radius: 5px;
  background-color: #f9f9f9;
  color: red;
  position: absolute;
  left: 48%;
  top: 50%;

  // width: 10%;
  z-index: 2;
}
.spinner_overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.2;
  z-index: 1000;
}
 .sidebar ul li a .icon {
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}
 .sidebar ul li a:hover,
 .sidebar ul li a.active {
  color: #251438;

  background: white;
  border-right: 2px solid #251438;
}
 .sidebar ul li a.active {
  color: #251438;

  background: white;
  border-right: 2px solid #251438;
}
 .sidebar ul li a.inactive {
  color: #fff;

  background: #251438;
  border-right: 2px solid #251438;
}
 .sidebar ul li a:hover .icon,
 .sidebar ul li a.active .icon {
  color: #251438;
}

 .sidebar ul li a:hover:before,
 .sidebar ul li a.active:before {
  color: white;

  background: #251438;
  display: block;
}
.menu-font {
  font-size: 0.95rem;
  font-weight: 500;
}
.white-shadow {
  background: #fff !important;
  box-shadow: inset 0 0 5px #000000;
}
.container {
  min-height: 100vh !important;
}
