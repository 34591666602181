.input-wrapper {
  font-size: small !important;
  position: relative;
  width: calc(100% - 24px);
  .palceholder {
    display: flex;
    position: absolute;
    top: 10px;
    left: 8px;
    z-index: 2;
  }
  .text {
    font-weight: normal;
    font-size: 0.8125rem;;
    color: #a6b0cf;;
    margin-right: 5px;
  }
  .star {
    color:red;
  }
}
.min-width{
  min-width:150px
}
.ng-placeholder{
  padding-left: 20px;;
}
.validation {


  text-align : left !important;
  padding-left: 10%;
  &.error {
    color: red;
    font-size: 12px;
  }
  &.noIcon{
    padding-left: 0px !important;
  }
}

.iconlessWidth{
  width: 100% !important;
}
@media (max-width: 767.98px) {
  .input-wrapper {
    .text {
      font-size: 0.8125rem * .85 !important;
    }
  }
}
.password-icon{
  float: right;
  margin-right: 5px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}
