.carousel-html-content{
  // height:800px !important;

  height:500px;
  float: center;
}
.demo{
  margin-bottom: 60px
}
.carousel-html-content
{
  .carousel-cell{
    // background: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: sans-serif;
    border-radius: 15px;
width: 500px !important;
   }}

.carousel-cell{

  border-radius: 15px
}
