/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Custom Bootstrap Css File
*/


//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

@import "./node_modules/bootstrap/scss/bootstrap";

@import "custom/components/autocomplete";
@import "custom/components/accordion";
@import "custom/components/button";
@import "custom/components/card";
@import "custom/components/carousel";
@import "custom/components/modalpopup";
@import "custom/components/tab";
