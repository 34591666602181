.wl-accordion {
  width: 100%;
  height: inherit;
  background-color: red;
  color: #fff;

  .accordion-header {
    display: flex;
    height: 36px;
    cursor: pointer;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      font-size: 20px;
    }

    span {
      text-shadow: 0 6px 6px rgba(0, 0, 0, 0.66);
      font-weight: 600;
    }
  }
  .accordion-body {
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    font-size:30px;

    &.collapsed {
      max-height: 0px;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}

.wl-accordion-general {
  width: 100%;
  height: inherit;
  // background-color: #fff;
  // .blue-opacity {
  //   background: rgba(62, 148, 247, 0.29) !important;
  // }
  .scrollbar {
    overflow-y: scroll;
    max-height: 200px;
    overflow-x: none;
  }
  .accordion-header {
    display: flex;
    height: 36px;
    cursor: pointer;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
    }
    // span {
    //   text-shadow: 0 6px 6px rgba(0, 0, 0, 0.66);
    //   font-weight: $font-weight-bold;
    // }
  }

  .accordion-body {
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    &.collapsed {
      max-height: 0px;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
// .blue-opacity {
//   background: rgba(62, 148, 247, 0.29) !important;
// }
.white-icon{
  color:rgb(243, 215, 215) !important;
  background-color: blue !important;
}
.navyblue-icon{
  color:blue !important;
  background-color: #fff !important;
}
.yellow-header{


  background-color: #ff9400;
  border-radius: 5px
;
}
.yellow-opacity{
  background: rgba(255,148,0, 0.44) !important;

}
.yellow-light{
  background: #fabe6b !important;

}
.white-shadow{
  background: #fff !important;
  box-shadow:         inset 0 0 5px #000000;


}
.white{
  background: #fff !important;

border-radius:5px ;


}
.white-yellow{
  background: #fff !important;
  color:#ff9400 !important;
}

