.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-block {
  display: inline-block;
}
.horizontal-line-grey {
  border-bottom: 2px solid #dee2e6;
}
* {
  box-sizing: border-box;
}

.zoom {
  padding: 50px;

  transition: transform 0.2s;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(1.8); /* IE 9 */
  -webkit-transform: scale(1.8); /* Safari 3-8 */
  transform: scale(1.8);
}
.carousel-cell {
  // background: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: sans-serif;
  border-radius: 15px;
}
.icon-spacing {
  padding-left: 150px;
}
.details-sub-heading {
  font-size: 15px;
  font-weight: 600;
}
.heading {
  font-size: 25px;
  font-weight: 500;
}
.heading {
  font-size: 25px;
  font-weight: 500;
}
.accordion-link {
  font-size: 80%;
  color: black;
}
.screenshot-heading {
  font-weight: 800;
  font-size: 85%;
}
.screenshot-padding {
  padding-bottom: 50px !important;
}
.details-heading {
  font-size: 20px;
  font-weight: 800;
  color: #251438;
}
.link-accordion-position {
  position: absolute;
  right: 20px;
  z-index: 1000;
}
.relative {
  position: relative;
}
