
.modal-xl .modal-lg {
  max-width: 90%;
}
.img-thick-border{
  border: 8px solid #54435d;
}
.img-size{
  width:250px;height:250px;
}
.modal-position{
  position: fixed;
}
