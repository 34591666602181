/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts


//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

@import "./node_modules/bootstrap/scss/mixins.scss";


@import "custom/pages/dashboard";
@import "custom/pages/dashboard-details";
@import "custom/pages/dashboard-list";
@import "custom/pages/login";
